// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acknowledgement-js": () => import("./../../../src/pages/acknowledgement.js" /* webpackChunkName: "component---src-pages-acknowledgement-js" */),
  "component---src-pages-archiveapply-js": () => import("./../../../src/pages/archiveapply.js" /* webpackChunkName: "component---src-pages-archiveapply-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-research-sdg-js": () => import("./../../../src/pages/research/sdg.js" /* webpackChunkName: "component---src-pages-research-sdg-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

