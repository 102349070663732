/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
)

export const onClientEntry = () => {
  // This is very jank and is likely to break. Too bad!
  const domainArray = document.domain.split(".")
  console.log(domainArray)
  if (domainArray[1] === "netlify") {
    if (domainArray[0] === "risingyouth" || domainArray[0] === "jeunesenaction")
      window.location.replace(
        domainArray[0] === "risingyouth"
          ? "https://www.risingyouth.ca/"
          : "https://www.jeunesenaction.com/"
      )
    return
  }
  if (domainArray[2] === "netlify") {
    domainArray.pop()
  }
  if (domainArray.length === 3 && domainArray[0] !== "www") {
    const subdomain = domainArray[0]
    console.log(subdomain)
    if (subdomain === "events") {
      // redirect to main site
      if (process.env.NODE_ENV === "development") {
        // dev
        window.location.replace(`http://${domain}:8000`)
      } else if (domainArray[2] === "netlify") {
        window.location.replace(`http://www.${domain}.app`)
      } else {
        window.location.replace(`http://www.${domain}`)
      }
    } else {
      // partner referral
      fetch(
        `https://research.tigweb.org/airtable/ry-subdomains.html?Subdomain=${subdomain}`,
        {
          method: "GET",
        }
      )
        .then(res => res.json())
        .then(data => {
          const { Name, ID } = data
          if (Name && ID) {
            const date = new Date()
            date.setDate(date.getDate() + 60)
            const domain = domainArray.splice(1).join(".")
            console.log(domain)
            
            document.cookie = `referType=Organization; expires=${date.toUTCString()}; domain=${domain}; path=/`
            
            document.cookie = `hostrefer=${Name}; expires=${date.toUTCString()}; domain=${domain}; path=/`

            document.cookie = `hostreferAirtableID=${ID}; expires=${date.toUTCString()}; domain=${domain}; path=/`

            document.cookie = `hostreferSubdomain=${subdomain}; expires=${date.toUTCString()}; domain=${domain}; path=/`

            console.log(`${Name} -> ${ID}`)
            if (process.env.NODE_ENV === "development") {
              // dev
              window.location.replace(`http://${domain}:8000/`)
            } else if (domainArray[2] === "netlify") {
              window.location.replace(`http://www.${domain}.app/`)
            } else {
              window.location.replace(`http://www.${domain}/`)
            }
          }
        })
    }
  }

  // event tracking/referrals
  if (typeof window !== "undefined") {
    // only check when it's the home page
    console.log(document.location.pathname)
    if (document.location.pathname === "/") {
      const eventAlias = new URLSearchParams(window.location.search).get(
        "eventAlias"
      )
      if (eventAlias !== null) {
        fetch(
          `https://research.tigweb.org/airtable/ry-events.html?Alias=${eventAlias}`,
          {
            method: "GET",
          }
        )
          .then(res => res.json())
          .then(data => {
            const { Name, ID } = data
            if (Name && ID) {
              const date = new Date()
              date.setDate(date.getDate() + 60)
              const domain = domainArray.splice(1).join(".")
              console.log(domain)
              
              document.cookie = `referType=Event; expires=${date.toUTCString()}; domain=${domain}; path=/`

              document.cookie = `eventrefer=${Name}; expires=${date.toUTCString()}; domain=${domain}; path=/`

              document.cookie = `eventreferAirtableID=${ID}; expires=${date.toUTCString()}; domain=${domain}; path=/`

              document.cookie = `eventreferAlias=${eventAlias}; expires=${date.toUTCString()}; domain=${domain}; path=/`
            }
          })
      }
    }
  }
}
